import accountServices from '../../../services/account'
export default {
    data() {
        return {
            payload: {},
            payload1: {},
            codeValue: '',
            isCode: false,
            isPassword: true,
            codereq: '',
            numValue: '',
            isNum: false,
            numreq: '',
            otpNumber: '',
        }
    },
    created(){
        if (this.$route && this.$route.query.c && this.$route.query.e) {

            this.payload = {
                token: this.$route.query.c,
                email: this.$route.query.e,
                code: this.$route.query.y,
                password: null,
                confirm_password: null,

            }
        } else {
            this.$router.push({name: 'Login'})
        }
    },
    methods: {
        verifyAccount() {
            this.payload.phone_number = this.numValue
            accountServices.verifyAccount(this.payload).then(resp => {
                if(resp.data.d!=undefined && resp.data.d.Challenge == 'MFA')
                {
                    this.otpNumber = resp.data.d.MFA.substr(resp.data.d.MFA.length - 4)
                    this.isPassword = false
                    this.isCode = true
                    this.codereq = 'Y'
                    this.isNum = false
                    this.numreq = ''
                }
                else if(resp.data.d!=undefined && resp.data.d.Challenge == 'NotishpereAdminPhoneRequired')
                {
                    this.isPassword = false
                    this.isNum = true
                    this.numreq = 'Y'
                    this.isCode = false
                    this.codereq = ''
                }
                else
                {
                    if(!resp.error) {
                        this.$router.push({name: 'SuccessPage', params: {
                            title: 'Account Verified!',
                            subTitle: 'You have successfully updated the password',
                            icon: 'far fa-check-circle'
                        }})
                    }
                }
            })
        },
        acceptNumber() {
            var x = this.numValue.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.numValue = !x[2] ? x[1] :  + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
        },
        verifyAccountwithCode() {
            this.payload1 = {
                email: this.payload.email,
                password: this.payload.password,
                verification_code: this.codeValue
            }
            accountServices.verifyCode(this.payload1).then(resp => {
                if(!resp.error) {
                    this._showToast('Your phone number has been verified', { variant: 'success' })
                    this.$router.push({name: 'SuccessPage', params: {
                        title: 'Account Verified!',
                        subTitle: 'You have successfully updated the password',
                        icon: 'far fa-check-circle'
                    }})
                }
            })
        },
        resend() {
            this.payload = {
                email: this.payload.email,
                password: this.payload.password
            }
            accountServices.resendCode(this.payload).then(resp => {
                if (!resp.error) {
                    this.codeValue = ''
                    this._showToast('OTP sent successfully', { variant: 'success' })
                }
                else
                {
                    this._showToast(resp.error.message, { variant: 'warning' })
                }
            })
        }
    }
}